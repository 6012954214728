export var SubscriptionPlatformType;
(function (SubscriptionPlatformType) {
    SubscriptionPlatformType["Starter"] = "STARTER";
    SubscriptionPlatformType["Advanced"] = "ADVANCED";
    SubscriptionPlatformType["Professional"] = "PROFESSIONAL";
})(SubscriptionPlatformType || (SubscriptionPlatformType = {}));
export var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType["Trader"] = "TRADER";
    SubscriptionType["Client"] = "CLIENT";
})(SubscriptionType || (SubscriptionType = {}));
export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["Trialing"] = "TRIALING";
    SubscriptionStatus["Active"] = "ACTIVE";
    SubscriptionStatus["Canceled"] = "CANCELED";
    SubscriptionStatus["Incomplete"] = "INCOMPLETE";
    SubscriptionStatus["IncompleteExpired"] = "INCOMPLETE_EXPIRED";
    SubscriptionStatus["PastDue"] = "PAST_DUE";
    SubscriptionStatus["Unpaid"] = "UNPAID";
})(SubscriptionStatus || (SubscriptionStatus = {}));
